import ModalStokProduk from '@/components/ModalStokProdukComponent';
import { getDetailProdukWithStok } from '@/services/produk';
import Produk from '@/types/Produk';
import { Badge, Calendar, PageHeader, Skeleton, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function HalamanStokProduk() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [produk, setProduk] = useState<Produk | null>(null);
  const [selectedStok, setSelectedStok] = useState<{
    jumlah: number;
    tanggal: Moment;
  }>(null);

  useEffect(() => {
    // setLoading(true);
    getDetailProdukWithStok(id as string)
      .then(({ data }) => setProduk(data))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <Skeleton active className="p-5" />;

  return (
    <>
      <ModalStokProduk
        visible={!!selectedStok}
        produk_id={produk.id}
        stok={selectedStok}
        onCancel={() => setSelectedStok(null)}
        onFinish={(values) => {
          setSelectedStok(null);
          setProduk((old) => ({
            ...old,
            stok: [
              ...old.stok.filter(
                (item) =>
                  moment(item.available_at).format('Do-MM-yyyy') !==
                  values.tanggal.format('Do-MM-yyyy'),
              ),
              {
                id: parseInt(values.tanggal.format('X')),
                available_at: values.tanggal.toISOString(),
                jumlah: values.jumlah,
              },
            ],
          }));
        }}
      />
      <PageHeader
        onBack={() => navigate(`/produk/${id}`)}
        breadcrumb={{
          routes: [
            {
              path: '/produk',
              breadcrumbName: 'Daftar Produk',
            },
            {
              path: `/produk/${id}`,
              breadcrumbName: 'Detail',
            },
            {
              path: `/produk/${id}/stok`,
              breadcrumbName: 'Stok',
            },
          ],
          itemRender: (route, _, routes) => {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
          },
        }}
        title="Stok Produk"
        subTitle="Perbaharui jumlah stok produk"
      />
      <section className="p-5">
        <div className="flex space-x-5 mb-5">
          <div className="flex-none">
            <img
              src={produk?.banner}
              alt={produk?.nama}
              className="w-20 md:w-32 rounded"
            />
          </div>
          <div className="flex-grow flex flex-col justify-center">
            <h1 className="font-bold text-xl md:text-2xl mb-0">{produk?.nama}</h1>
            <Link to={`/kategori/produk/${produk?.kategori.id}`} className="font-bold">
              {produk?.kategori.nama}
            </Link>
          </div>
        </div>
        <div className="p-5 bg-white rounded">
          <Calendar
            mode="month"
            onSelect={(date) => {
              if (
                moment()
                  .set('hour', 0)
                  .set('minute', 0)
                  .set('second', 0)
                  .isSameOrBefore(date)
              ) {
                setSelectedStok({
                  jumlah: produk.stok?.find(
                    (item) =>
                      moment(item.available_at).format('Do-MM-yyyy') ===
                      date.format('Do-MM-yyyy'),
                  )?.jumlah,
                  tanggal: date,
                });
              }
            }}
            dateCellRender={(current) => {
              for (const stok of produk.stok) {
                if (
                  current.format('yyyy-MM-Do') ===
                  moment(stok.available_at).format('yyyy-MM-Do')
                ) {
                  return (
                    <ul className="list-stok">
                      <li>
                        {stok.jumlah > 0 ? (
                          <>
                            {moment(stok.available_at).isBefore(moment()) ? (
                              <span className="text-gray-400 text-xl">{stok.jumlah}</span>
                            ) : (
                              <Tooltip title={`Tersedia ${stok.jumlah} ${produk.unit}`}>
                                <Badge
                                  status={'success'}
                                  text={
                                    <span className="text-green-700 text-xl">
                                      {stok.jumlah}
                                    </span>
                                  }
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          <Badge
                            status={'error'}
                            text={<span className="text-red-700 text-xl">Habis</span>}
                          />
                        )}
                      </li>
                    </ul>
                  );
                }
              }
            }}
            disabledDate={(current) => {
              return moment().add(-1, 'days') >= current;
            }}
          />
        </div>
      </section>
    </>
  );
}
